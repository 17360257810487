import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./index.css";

const ContactMe = (props) => {
	const { text} = props;
	return (
        <div>
        <div className="card-section">
            <div className="card-section-title">
                Contact Me
                <div className="underline-shape"></div>
            </div>
        </div>
        <div className="contact-main">
            <div className="contact-form-container">
                <div className="contact-form-title">
                    Contact Form
                    <div className="contact-form-text">
                    If you prefer, you can send me a message directly using the contact form below. Just fill in your details and your message, and I'll get back to you as soon as possible.
                </div>
                </div>

 

                <form action="" method="post" className="contact-form">

                    <label className="contact-form-label" htmlFor="contact-form-name"> Name</label>
                    <input id="contact-form-name" className="contact-form-input contact-form-name" type="text" />

                    
                    <label className="contact-form-label" htmlFor="contact-form-email"> Email</label>
                    <input id="contact-form-email"  className="contact-form-input contact-form-email" type="email" />


                    <label className="contact-form-label" htmlFor="contact-form-message"> Message</label>
                    <textarea  id="contact-form-message" className="contact-form-input contact-form-message" type="text" />

                    <div>
                    <input className="contact-form-submit" type="submit" value="Send" />
                    </div>
                </form>

            </div>

            <div className="contact-form-container contact-get-in-touch">
                <img src="contact-me.png" />

                <div className="contact-form-title ">

                    Get in Touch
                    <div className="contact-form-text text">

                I'd love to hear from you! Whether you have questions, inquiries, or if you'd just like to say hello, please don't hesitate to reach out. I'm here to assist you in any way I can.

                </div>

                </div>
                
                </div>


        </div>
    
    </div>
	);
};

export default ContactMe;
