import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./index.css";

const ExploreButton = (props) => {
	const { text} = props;
	return (
        <div className="explore-button">
            {text}
        </div>
	);
};

export default ExploreButton;
