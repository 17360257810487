import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExploreButton from "./Buttons/ExploreButton";
import "./styles/service-card.css";

const ServiceCard = (props) => {
	const { image, title, body, imageAlign } = props;

	return (
		<div className="service-card">
			<div className="service-card-container">
				{imageAlign === "left" ? (
					<>
						<img
							className="service-card-image"
							src={image}
							alt=""
							style={{
								borderRadius: "20px 00px 0px 20px",
							}}
						/>
						<div className="service-card-body">
							<div className="service-card-sub-body">
								<div className="service-card-title">
									{title}
								</div>

								<div className="service-card-text">{body}</div>
								<ExploreButton text={"Explore"} />
							</div>
						</div>
					</>
				) : (
					<>
						<div className="service-card-body">
							<div className="service-card-sub-body">
								<div className="service-card-title">
									{title}
								</div>

								<div className="service-card-text">{body}</div>
								<ExploreButton text={"Explore"} />
							</div>
						</div>
						<img
							className="service-card-image"
							src={image}
							alt=""
							style={{
								borderRadius: "0px 20px 20px 0px",
							}}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default ServiceCard;
