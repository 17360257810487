import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTwitter,
	faGithub,
	faStackOverflow,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import Article from "../components/homepage/article";
import Works from "../components/homepage/works";
import Card from "../components/common/card";
import ServiceCard from "../components/common/service-card";
import ContactMe from "../components/ContactMe";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";
import myArticles from "../data/articles";

import "./styles/homepage.css";

const Homepage = () => {
	const [stayLogo, setStayLogo] = useState(false);
	const [logoSize, setLogoSize] = useState(80);
	const [oldLogoSize, setOldLogoSize] = useState(80);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			let scroll = Math.round(window.pageYOffset, 2);

			let newLogoSize = 80 - (scroll * 4) / 10;

			if (newLogoSize < oldLogoSize) {
				if (newLogoSize > 40) {
					setLogoSize(newLogoSize);
					setOldLogoSize(newLogoSize);
					setStayLogo(false);
				} else {
					setStayLogo(true);
				}
			} else {
				setLogoSize(newLogoSize);
				setStayLogo(false);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [logoSize, oldLogoSize]);

	const currentSEO = SEO.find((item) => item.page === "home");

	const logoStyle = {
		display: "flex",
		position: stayLogo ? "fixed" : "relative",
		top: stayLogo ? "3vh" : "auto",
		zIndex: 999,
		border: stayLogo ? "1px solid white" : "none",
		borderRadius: stayLogo ? "50%" : "none",
		boxShadow: stayLogo ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none",
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{INFO.main.title}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="home" />

				<div className="content-wrapper">
					<div className="homepage-logo-container">
						<div style={logoStyle}>
							<Logo width={logoSize} link={false} />
						</div>
					</div>
					<img
						src="yellow-bg.svg"
						alt="about"
						className="hero-image homepage-image"
					/>
					<div className="homepage-container">
						<div className="homepage-first-area">
							<div className="homepage-first-area-left-side">
								<div className="jobtitle homepage-jobtitle">
									{INFO.homepage.jobtitle}
								</div>
								<div className="title homepage-title">
									{INFO.homepage.title}
								</div>

								<div className="subtitle homepage-subtitle">
									{INFO.homepage.description}
								</div>
							</div>
						</div>

						<div className="homepage-socials">
							<a
								href={INFO.socials.twitter}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faTwitter}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.github}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faGithub}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.stackoverflow}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faStackOverflow}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.instagram}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faInstagram}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={`mailto:${INFO.main.email}`}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faMailBulk}
									className="homepage-social-icon"
								/>
							</a>
						</div>

						<div className="card-section">
							<div className="card-section-title">
								What I bring to the Table ?
								<div className="underline-shape"></div>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "flex-row",
									justifyContent: "space-between",
								}}
							>
								<Card
									title={"Expertise in Diverse Technologies"}
									body={
										"Proficient in Python, JavaScript, C#, and more, coupled with hands-on experience with frameworks like Django, Flask, React, and NodeJS."
									}
								/>
								<Card
									title={"DevOps Mastery"}
									body={
										"Adept at streamlining software development processes, from cloud infrastructure setup to performance optimization. "
									}
								/>
								<Card
									title={"A Passion for Continuous Learning"}
									body={
										"Always updated with the latest in software and technology, ensuring that the solutions I deliver are modern, scalable, and efficient."
									}
								/>
							</div>
						</div>
						<>
							<div className="card-section">
								<div className="card-section-title">
									Services
									<div className="underline-shape"></div>
								</div>
							</div>

							<ServiceCard
								image="new/Software-Development.jpg"
								title={"Software Development"}
								body={
									"As an individual developer, I'm passionate about crafting custom software solutions. From concept to code, I take pride in turning your unique ideas into functional and beautiful applications that align with your vision and business objectives."
								}
								imageAlign={"left"}
							/>

							<ServiceCard
								image="new/Mobile-Development.jpg"
								title={"Mobile Development"}
								body={
									"With a focus on iOS and Android platforms, I specialize in the art of mobile app development. As an individual developer, I'm dedicated to creating intuitive and visually engaging mobile apps that resonate with your users and bring your concepts to life."
								}
								imageAlign={"right"}
							/>
							<ServiceCard
								image="new/DevOps.jpg"
								title={"DevOps Services"}
								body={
									"I offer DevOps services that are tailored to your specific needs. As an individual developer, I believe in the power of automation, continuous integration, and deployment to enhance your software development process, ensuring faster releases and superior quality."
								}
								imageAlign={"left"}
							/>
							<ServiceCard
								image="new/software-maintainence.jpg"
								title={"Software Maintenance Service"}
								body={
									"I act as the guardian of your software, providing meticulous maintenance and support. With proactive monitoring and swift bug fixes, I ensure your applications keep running smoothly, evolving as your needs change, and consistently delivering top performance."
								}
								imageAlign={"right"}
							/>

						</>

						<div><
							/div>


						<ContactMe />

						<div className="page-footer">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;
